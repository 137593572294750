import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Portal"

// Dashboard
import DecorationDashboard from "../pages/Dashboard/decorationDash"
import Dashboard from "../pages/Dashboard/index"
import AttendenceForm from "../pages/attendence/attendenceFormIndex"
import AttendenceList from "../pages/attendence/attendenceTableIndex"
import classes from "../pages/class/classIndex"
import Courses from "../pages/courses/courseIndex"
import Enrollment from "../pages/enrollment/enrollemntIndex"
import Expense from "../pages/expenses/expenseIndex"
import Fee from "../pages/fee/feeIndex"
import Income from "../pages/income/incomeIndex"
import Receipt from "../pages/receipt/receiptIndex"
import Students from "../pages/students/studentsIndex"
import Teachers from "../pages/teachers/teacherIndex"

// Reports
import Perfomance from "../pages/manageActivity/Performance"
import Tasks from "../pages/manageActivity/tasks"
import AttendenceReport from "../pages/reports/attendenceReport"
import EnrollmentReport from "../pages/reports/enrollmentReport"
import Feereport from "../pages/reports/feeReport"
import ReceiptReport from "../pages/reports/reaceiptReport"
import Statements from "../pages/reports/statements"

import Marks from "../pages/exam/marks"
import Employee from "../pages/hrm/employeeIndex"
import Payroll from "../pages/payroll/payroll"
import Roles from "../pages/roles/role"
import Customers from "../pages/service/customer"
import Service from "../pages/service/service"
import ServiceReceipt from "../pages/service/serviceReceipt"
import Subject from "../pages/subjects/subject"

// import Users from "../pages/users/index"
import LoginPortal from "../pages/Authentication/Loginportal"
import ViewAttendence from "../pages/Authentication/viewAttendence"
import Certificate from "../pages/certificate/Certification"
import Subscription from "../pages/susbcription/subscriptionIndex"
import Users from "../pages/users/index"

// inventory
import salesReport from "pages/decorationReports/salesReport"
import ServiceDept from "pages/decorationReports/serviceDept"
import serviceReceiptReport from "pages/decorationReports/serviceReceiptReport"
import ServiceReport from "pages/decorationReports/serviceReport"
import StockReport from "pages/decorationReports/stockReport"
import Branch from "pages/inventory/branch"
import CustomInvoice from "pages/inventory/customInvoice"
import Invoice from "pages/inventory/invoice"
import purchase from "pages/inventory/purchase"
import SalesReceipts from "pages/inventory/salesReceipt"
import Stock from "pages/inventory/stock"
import Supplier from "pages/inventory/supplier"
import StudentComplain from "../pages/Authentication/studentComplain"
import Complain from "../pages/complain/complain"
import Note from "../pages/note/note"
const userRoutes = [
  { path: "/stock", component: Stock },
  { path: "/salesReceipts", component: SalesReceipts },
  { path: "/sales", component: Invoice },
  { path: "/purchase", component: purchase },
  { path: "/supplier", component: Supplier },
  { path: "/customInvoice", component: CustomInvoice },
  { path: "/branch", component: Branch },

  { path: "/dashboard", component: Dashboard },
  { path: "/DecorationDashboard", component: DecorationDashboard },

  { path: "/courses", component: Courses },
  { path: "/class", component: classes },
  { path: "/certificate", component: Certificate },
  { path: "/teacher", component: Teachers },
  { path: "/student", component: Students },
  { path: "/stdenrollment", component: Enrollment },
  { path: "/fee", component: Fee },
  { path: "/receipt", component: Receipt },
  { path: "/income", component: Income },
  { path: "/expense", component: Expense },
  { path: "/attendence", component: AttendenceForm },
  { path: "/attendencelist", component: AttendenceList },
  { path: "/subscription", component: Subscription },
  { path: "/customer", component: Customers },
  { path: "/service", component: Service },
  { path: "/servicereceipt", component: ServiceReceipt },
  { path: "/marks", component: Marks },
  { path: "/subject", component: Subject },
  { path: "/payroll", component: Payroll },

  // Reports route
  { path: "/feeReport", component: Feereport },
  { path: "/receiptReport", component: ReceiptReport },
  { path: "/statement", component: Statements },
  { path: "/attendencereport", component: AttendenceReport },
  { path: "/enrollmentreport", component: EnrollmentReport },

  { path: "/users", component: Users },
  { path: "/employee", component: Employee },
  { path: "/roles", component: Roles },
  { path: "/perfomance", component: Perfomance },
  { path: "/tasks", component: Tasks },

  // // //profile
  { path: "/profile", component: UserProfile },

  // secoration Report
  { path: "/salesReport", component: salesReport },
  { path: "/ServiceReport", component: ServiceReport },
  { path: "/serviceReceiptReport", component: serviceReceiptReport },
  { path: "/stockRport", component: StockReport },
  { path: "/ServiceDept", component: ServiceDept },
  { path: "/note", component: Note },
  { path: "/complain", component: Complain },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },

  { path: "/loginportal", component: LoginPortal },
  { path: "/portal", component: Register },
  { path: "/viewAttendence", component: ViewAttendence },
  { path: "/studentComplain", component: StudentComplain },
]

export { authRoutes, userRoutes }
